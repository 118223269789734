import React            from 'react'
import { HashLink }     from 'react-router-hash-link'
import Styles           from './YearAheadSection.module.css'


const YearAheadSection = () => {

    return (
    <>
    <div id="YearAheadReadingSection" className={Styles.yearAheadSection}>

        <div className={Styles.textContainer}>
            <h2>Get a StarGoddess Personalised Year-Ahead Horoscope Reading</h2>
            <h3>Discover what's in store for you</h3>
            <p>
                Indulge in a personalized annual forecast from StarGoddess!
                Discover what the stars have in store for you over the next year,
                tailored to your exact place, time, and date of birth.
            </p>
            <p>
                Unveil vital insights into your career, love life, health, and more,
                and step confidently into the future with celestial guidance
                lighting your way.
            </p>

            <HashLink smooth to='/year-ahead-reading' className={`${Styles.ctaButton}`}>Find Out More</HashLink>

        </div>

        <div className={Styles.imageContainer}>
            <img src="/img/squeezed-year-ahead-crystal-ball.jpg" alt="a universe in a crystal ball" className={Styles.image} loading="lazy" />
        </div>
    </div>
    </>
    )
}

export default YearAheadSection

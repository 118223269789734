import React, { useState, useEffect }   from 'react'
// import { useNavigate }      from 'react-router-dom'
import axiosInstance        from '../utils/axiosInstance'
import { formatDateString } from '../utils/utils'

import Spinner              from '../components/Spinner'
import ProfileButtons       from '../components/ProfileButtons'

import styles               from './ViewReading.module.css'
import { b64Encode }        from '../utils/utils'


const ViewReading = ({ orderRequestUuid }) => {

    const [loading, setLoading] = useState(true);
    const [reading, setReading] = useState('');
    // const navigate              = useNavigate();

    const handleDownloadPdf = (fmt='a4') => {
        // TODO: protected route requires that you
        // use axiosInstance.get
        // But that means you can't open in new tab...easily.
        const nav2 =
            `${process.env.REACT_APP_API_ENDPOINT}/api/reading/pdf?oId=${b64Encode(orderRequestUuid)}&fmt=${fmt}`;

        window.location.href = nav2;
    }

    const renderReadingContent = () => {

        if (loading)
        {
            return (
                <>
                    <h2>One moment please..</h2>
                    <Spinner sizeClass="small" />
                </>
            );
        }

        switch(reading.type)
        {
        case 'year-ahead':

            const usrProfileData = { ...reading.identity, profilePic: reading.profilePic };

            return (
                <>
                <div className={styles.textContainer}>
                    <h1>{reading.product_name} for {reading.identity.name}, starting { formatDateString(reading.date_for) }</h1>

                    <ProfileButtons profile={ usrProfileData } />

                    <div className={styles.buttonRow}>
                        <button className={styles.ctaButton} onClick={() => handleDownloadPdf('a4')}>Download PDF</button>
                        <button className={styles.ctaButton} onClick={() => handleDownloadPdf('a6')}>Mobile Friendly PDF</button>
                    </div>

                    <div className={styles.reading} dangerouslySetInnerHTML={{ __html: reading.reading }} />
                </div>
                </>
            );

        default:
            return (
                <>
                    <div className={styles.textContainer}>
                    <h1>Your {reading.product_name} Reading</h1>
                    <div className={styles.buttonRow}>
                        <button className={styles.ctaButton} onClick={() => handleDownloadPdf('a4')}>Download PDF</button>
                        <button className={styles.ctaButton} onClick={() => handleDownloadPdf('a6')}>Mobile Friendly PDF</button>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: reading.reading }} />
                    </div>
                </>
            );
        }
    }


    useEffect(() => {

        const fetchReading = async (orderRequestUuid) => {
            try
            {
                setLoading(true);

                const response = await axiosInstance.get(
                    `/api/reading/view?orderRequestUuid=${orderRequestUuid}`);

                if (response.data)
                    setReading(response.data);
            }
            catch (error)
            {
                console.error(`⚠ Error fetching reading:\n${error}\n\n`);
                setReading('Error fetching reading.');
            }
            finally
            {
                setLoading(false);
            }
        };

        if (orderRequestUuid)
            fetchReading(orderRequestUuid);

    }, [orderRequestUuid]);

    return (
        <>
        <div className={styles.page}>
            { renderReadingContent() }
        </div>
        </>
    );
};

export default ViewReading

import { loadStripe }        from '@stripe/stripe-js'
import axiosInstance         from '../utils/axiosInstance'
import { getNowUTCDate }     from '../utils/utils'

import {
    getJourneyObjects,
    journeySummaryToConsole,
    getJourneyData,
    completeStep,
    processStep
} from '../session/journeyState'


const fetchIdentityInfo = async (partnerIdentity = false) => {
    try
    {
        const endPoint = partnerIdentity ?
            '/api/id/getPartnerIdentity' : '/api/id/getAccountIdentity';

        const response =
            await axiosInstance.get(endPoint);

        return response.data;
    }
    catch (err)
    {
        console.error('⚠ Exception in fetchIdentityInfo:\n', err, '\n\n');
        return false;
    }
}


const signInJourney = async (navigate) => {

    const { stepName } = getJourneyObjects();

    switch (stepName)
    {
        case 'Sign In':
            processStep(navigate);
            break;

        case 'Collect Birthday Details':
        {
            const id = await fetchIdentityInfo();
            if (id?.identity?.isComplete)
            {
                completeStep();
                continueJourney(navigate);
            }
            else
            {
                processStep(navigate);
            }
        }
        break;

        case 'Dashboard':
            processStep(navigate);
            break;

        default:
            console.log('signIn journey reached default case..');
            processStep(navigate);
            break;
    }
}

const registerJourney = async (navigate) => {

    const { stepName } = getJourneyObjects();

    console.log(`registerJourney: ${stepName}\n`);

    if (! stepName)
    {
        return;
    }

    switch (stepName)
    {
        case 'Reg Options':
            processStep(navigate);
            break;

        case 'Collect Birthday Details':
        {
            const id = await fetchIdentityInfo();
            if (id?.identity?.isComplete)
            {
                completeStep();
                continueJourney(navigate);
            }
            else
            {
                processStep(navigate);
            }
        }
        break;

        default:
            console.log('register journey reached default case..');
            processStep(navigate);
            break;
    }
}

const buyYearAheadReadingJourney = async (navigate, isAuthenticated) => {

    const { /*journeyName,*/ stepName } = getJourneyObjects();

    switch (stepName)
    {
        case 'Sign In':
            if (isAuthenticated)
            {
                completeStep();
                continueJourney(navigate, isAuthenticated);
            }
            else
            {
                processStep(navigate);
            }
            break;

        case 'Collect Birthday Details':
        {
            const id = await fetchIdentityInfo();
            if (id?.identity?.isComplete)
            {
                completeStep();
                continueJourney(navigate);
            }
            else
            {
                processStep(navigate);
            }
        }
        break;

        case 'Collect Start Date':
            processStep(navigate);
            break;

        case 'Pay':
            try
            {
                const startDate         = getJourneyData('yearAheadStartDate') || getNowUTCDate();
                const noRefundConsent   = getJourneyData('yearAheadNoRefundConsent') || false;

                const response =
                    await axiosInstance.post('/api/shop/orderRequest',
                        {
                            productName:        'Year-Ahead Reading',
                            date:               startDate,
                            noRefundConsent:    noRefundConsent
                        }
                    );

                if (response.status >= 200 && response.status <= 299)
                {
                    completeStep();

                    // on successful payment, stripe will redirect to /order-complete or /cancel-order
                    // as defined in the /api/shop/orderRequest handler
                    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`);
                    await stripe.redirectToCheckout({ sessionId: response.data.id });
                }
            }
            catch (err)
            {
                completeStep();
                console.error('⚠️ Exception creating checkout session:\n', err, '\n\n');
            }
            break;

        default:
            console.error('☠ reached default case..');
            // processStep(navigate);
            break;
    }
}

const buyLoveCompatibiltyReadingJourney = async (navigate, isAuthenticated) => {


    const { /*journeyName,*/ stepName } = getJourneyObjects();

    switch (stepName)
    {
        case 'Sign In':
            if (isAuthenticated)
            {
                completeStep();
                continueJourney(navigate, isAuthenticated);
            }
            else
            {
                processStep(navigate);
            }
            break;

        case 'Collect Birthday Details':
        {
            const id = await fetchIdentityInfo();
            if (id?.identity?.isComplete)
            {
                completeStep();
                continueJourney(navigate);
            }
            else
            {
                processStep(navigate);
            }
        }
        break;

        case 'Collect Partner Birth Details':
            processStep(navigate);
            break;

        case 'Pay':
            try
            {
                const noRefundConsent =
                    getJourneyData('loveCompatibilityNoRefundConsent') || false;

                const response =
                    await axiosInstance.post('/api/shop/orderRequest',
                        {
                            productName:        'Love-Compatibility Reading',
                            date:               getNowUTCDate(),
                            noRefundConsent
                        }
                    );

                if (response.status >= 200 && response.status <= 299)
                {
                    completeStep();

                    // on successful payment, stripe will redirect to /order-complete or /cancel-order
                    // as defined in the /api/shop/orderRequest handler
                    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`);
                    await stripe.redirectToCheckout({ sessionId: response.data.id });
                }
            }
            catch (err)
            {
                completeStep();
                console.error('⚠️ Exception creating checkout session:\n', err, '\n\n');
            }
            break;

        default:
            console.error('☠ reached default case..');
            // processStep(navigate);
            break;
    }
}

const buyTestReadingJourney = async (navigate, isAuthenticated) => {

    const { stepName } = getJourneyObjects();

    switch (stepName)
    {
        case 'Sign In':
            if (isAuthenticated)
            {
                completeStep();
                continueJourney(navigate, isAuthenticated);
            }
            else
            {
                processStep(navigate);
            }
            break;

        case 'Pay':
            try
            {
                console.log('test-purchase: pay reached\n\n')

                const noRefundConsent =
                    getJourneyData('testPurchaseNoRefundConsent') || false;

                const response =
                    await axiosInstance.post('/api/shop/orderRequest',
                        {
                            productName:        'Test Product',
                            date:               getNowUTCDate(),
                            noRefundConsent
                        }
                    );

                if (response.status >= 200 && response.status <= 299)
                {
                    // completeStep
                    //      - sets journey[i].complete = 1
                    //      - sets i += 1
                    completeStep();

                    // on successful payment, stripe will redirect to /order-complete or /cancel-order
                    // as defined in the /api/shop/orderRequest handler
                    const stripe = await loadStripe(`${process.env.REACT_APP_STRIPE_PUB_KEY}`);
                    await stripe.redirectToCheckout({ sessionId: response.data.id });
                }
            }
            catch (err)
            {
                completeStep();
                console.error('⚠️ Exception creating checkout session:\n', err, '\n\n');
            }
            break;

        default:
            console.error('☠ reached default case..');
            // processStep(navigate);
            break;
    }
}


const mailingListSubscribeJourney = async (navigate, isAuthenticated) => {

    const state = getJourneyObjects();

    const { stepName } = state;

    switch(stepName)
    {
        case 'Reg Options':
        {
            if (isAuthenticated)
            {
                completeStep();
                continueJourney(navigate, isAuthenticated);
            }
            else
            {
                console.error(`auth: ${isAuthenticated}`);
                processStep(navigate);
            }
            break;
        }

        case 'Collect Birthday Details':
        {
            const id = await fetchIdentityInfo();
            if (id?.identity?.isComplete)
            {
                completeStep();
                continueJourney(navigate);
            }
            else
            {
                processStep(navigate);
            }
        }
        break;

        case 'Notify Subscribe Confirmation':
            processStep(navigate);
            break;

        case 'Home':
            processStep(navigate);
            break;

        default:
        break;
    }
}

const continueJourney = async (navigate, isAuthenticated = false) => {

    const state = getJourneyObjects();

    const { journeyName } = state;

    switch (journeyName)
    {
        case 'sign-in':
            await signInJourney(navigate);
            break;

        case 'register':
            await registerJourney(navigate);
            break;

        case 'year-ahead-purchase':
            await buyYearAheadReadingJourney(navigate, isAuthenticated);
            break;

        case 'mailing-list-subscribe':
            await mailingListSubscribeJourney (navigate, isAuthenticated);
            break;

        case 'love-compatibility-purchase':
            await buyLoveCompatibiltyReadingJourney(navigate, isAuthenticated);
            break;

        case 'test-purchase':
            await buyTestReadingJourney(navigate, isAuthenticated);
            break;

        default:
            break;
    }
}

export {
    continueJourney,
    journeySummaryToConsole
}

import React, { useState, useEffect, useRef }   from 'react'
import { Link, useNavigate }                    from 'react-router-dom'
import { HashLink }                             from 'react-router-hash-link'
import { useAuth }                              from '../auth/AuthContext'

import { startJourney, endJourney, processStep } from '../session/journeyState'

import Styles from './Header.module.css'


const Header = () => {
    const { isAuthenticated, isAdmin, resetTokens } = useAuth();

    const isInitialMount            = useRef(true);
    const [scrolled, setScrolled]   = useState(false);
    const [menuOpen, setMenuOpen]   = useState(false);
    const navigate                  = useNavigate();


    const handleSignIn = () => {
        startJourney('sign-in');
        processStep(navigate);
    };

    const handleRegister = () => {
        startJourney('register');
        processStep(navigate);
    };

    const handleSignOut = () => {
        endJourney();
        resetTokens();

        window.location.href = '/';
    };

    useEffect(() => {
        const handleScroll = () => {
            const vh = window.innerHeight / 100;

            if (window.scrollY > 80 * vh)
                setScrolled(true);
            else
                setScrolled(false);
        };

        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isAuthenticated]);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <header className={`${Styles.header} ${scrolled ? Styles.scrolled : ''}`}>
            <nav className={Styles.menu}>
                <div className={Styles.hamburger} onClick={toggleMenu}>
                    &#9776;
                </div>
                <ul className={Styles.leftMenu}>
                    {!isAuthenticated && (
                        <>
                            <li><HashLink smooth to="/#HeroSection">Home</HashLink></li>
                            <li><HashLink smooth to="/#ForecastSection">Daily Horoscopes</HashLink></li>
                            <li><HashLink smooth to="/#LoveHoroscopeSection">Love Horoscope Reading</HashLink></li>
                            <li><HashLink smooth to="/#YearAheadReadingSection">Year-Ahead Reading</HashLink></li>
                            <li><HashLink smooth to="/#PersonalReadingSection">Personal Reading</HashLink></li>
                            <li><HashLink smooth to="/#AboutSection">About</HashLink></li>
                        </>
                    )}
                    {isAuthenticated && !isAdmin && (
                        <>
                            <li><HashLink smooth to="/#HeroSection">Home</HashLink></li>
                            <li><HashLink smooth to="/#ForecastSection">Daily Horoscopes</HashLink></li>
                            <li><HashLink smooth to="/#LoveHoroscopeSection">Love Horoscope Reading</HashLink></li>
                            <li><HashLink smooth to="/#YearAheadReadingSection">Year-Ahead Reading</HashLink></li>
                            <li><HashLink smooth to="/#PersonalReadingSection">Personal Reading</HashLink></li>
                            <li><HashLink smooth to="/#AboutSection">About</HashLink></li>
                            <li><HashLink smooth to="/dashboard">Dashboard</HashLink></li>
                            <li><Link onClick={handleSignOut}>Sign Out</Link></li>
                        </>
                    )}
                    {isAuthenticated && isAdmin && (
                        <>
                            <li><HashLink smooth to="/#HeroSection">Home</HashLink></li>
                            <li><HashLink smooth to="/dashboard">Dashboard</HashLink></li>
                            <li><Link onClick={handleSignOut}>Sign Out</Link></li>
                        </>
                    )}
                </ul>
                {!isAuthenticated && (
                    <ul className={Styles.rightMenu}>
                        <li><Link onClick={handleRegister}>Register</Link></li>
                        <li><Link onClick={handleSignIn}>Sign In</Link></li>
                    </ul>
                )}
            </nav>
            {menuOpen && (
                <div className={Styles.mobileMenu}>
                    <ul className={Styles.mobileMenuList}>
                        {!isAuthenticated && (
                            <>
                                <li><HashLink smooth to="/#HeroSection" onClick={toggleMenu}>Home</HashLink></li>
                                <li><HashLink smooth to="/#ForecastSection" onClick={toggleMenu}>Daily Horoscopes</HashLink></li>
                                <li><HashLink smooth to="/#LoveHoroscopeSection" onClick={toggleMenu}>Love Horoscope Reading</HashLink></li>
                                <li><HashLink smooth to="/#YearAheadReadingSection" onClick={toggleMenu}>Year-Ahead Reading</HashLink></li>
                                <li><HashLink smooth to="/#PersonalReadingSection" onClick={toggleMenu}>Personal Reading</HashLink></li>
                                <li><HashLink smooth to="/#AboutSection" onClick={toggleMenu}>About</HashLink></li>
                            </>
                        )}
                        { isAuthenticated && ! isAdmin && (
                            <>
                                <li><HashLink smooth to="/#HeroSection" onClick={toggleMenu}>Home</HashLink></li>
                                <li><HashLink smooth to="/#ForecastSection" onClick={toggleMenu}>Daily Horoscopes</HashLink></li>
                                <li><HashLink smooth to="/#LoveHoroscopeSection" onClick={toggleMenu}>Love Horoscope Reading</HashLink></li>
                                <li><HashLink smooth to="/#YearAheadReadingSection" onClick={toggleMenu}>Year-Ahead Reading</HashLink></li>
                                <li><HashLink smooth to="/#PersonalReadingSection" onClick={toggleMenu}>Personal Reading</HashLink></li>
                                <li><HashLink smooth to="/#AboutSection" onClick={toggleMenu}>About</HashLink></li>
                                <li><HashLink smooth to="/dashboard" onClick={toggleMenu}>Dashboard</HashLink></li>
                                <li><Link onClick={() => { handleSignOut(); toggleMenu(); }}>Sign Out</Link></li>
                            </>
                        )}
                        { isAuthenticated && isAdmin && (
                            <>
                                <li><HashLink smooth to="/#HeroSection" onClick={toggleMenu}>Home</HashLink></li>
                                <li><HashLink smooth to="/admin-dashboard" onClick={toggleMenu}>Dashboard</HashLink></li>
                                <li><Link onClick={() => { handleSignOut(); toggleMenu(); }}>Sign Out</Link></li>
                            </>
                        )}
                        { ! isAuthenticated && (
                            <>
                                <li><Link onClick={() => { handleRegister(); toggleMenu(); }}>Register</Link></li>
                                <li><Link onClick={() => { handleSignIn(); toggleMenu(); }}>Sign In</Link></li>
                            </>
                        )}
                    </ul>
                </div>
            )}
        </header>
    );
};

export default Header;

import React        from 'react'
import StarSignGrid from './StarSignGrid'
import SubscribeBox3 from './SubscribeBox3'

import Styles from './ForecastSection.module.css'

const ForecastSection = () => {
    return (
        <div id="ForecastSection" className={Styles.forecastSection}>
            <div className={Styles.column}>
                <h2>Your free daily Horoscopes</h2>
                <SubscribeBox3 />
                <StarSignGrid />
            </div>
        </div>
    );
};

export default ForecastSection;

import React, { useEffect, useRef, useState }   from 'react'
import { useNavigate  }                         from 'react-router-dom'

import { useAuth }                              from '../auth/AuthContext'

import { startJourney, setJourneyData }         from '../session/journeyState'
import { continueJourney }                      from '../session/journeyLogic'

import BuyNowBox                                from '../components/BuyNowBox'

import Styles from './TestFunnelPage.module.css'


const TestFunnelPage = () => {

    const { isAuthenticated }   = useAuth();
    const navigate              = useNavigate();
    const [consent, setConsent] = useState(false);
    const topRef                = useRef(null);


    const handleConsentChange = (consentValue) => {
        setConsent(consentValue);
    };

    const handleBuyNow = async () => {
        startJourney('test-purchase');
        setJourneyData('testPurchaseNoRefundConsent', consent);
        await continueJourney(navigate, isAuthenticated);
    };

    useEffect(() => {
        // window.scrollTo({ top: 0, behavior: 'smooth'});
        topRef.current?.scrollIntoView({ top: 0, behavior: 'smooth'});
    }, []);


    return (
    <>

    <div ref={topRef}></div>

    <div className={Styles.page}>
        <div className={Styles.container}>

            <h2>Test Product</h2>

            <div className={Styles.textContainer}>
                <h3>Fingers Crossed</h3>

                <p>
                Don’t leave it up to chance—let the StarGoddess guide you!
                </p>

                <p>
                Let the universe illuminate your path.
                </p>
            </div>

            <BuyNowBox
                handleSubmit={handleBuyNow}
                onConsentChange={handleConsentChange}
                price="0.99*"
                normalPrice="29.99"
            />

        </div>
    </div>
    </>
    );
}

export default TestFunnelPage

import React                        from 'react'
import { Helmet }                   from 'react-helmet'

import HeroSection2                 from '../components/HeroSection2'
import ForecastSection              from '../components/ForecastSection'
import LoveCompatibilitySection     from '../components/LoveCompatibilitySection'
import YearAheadSection             from '../components/YearAheadSection'
import PersonalReadingSection       from '../components/PersonalReadingSection'
import AboutSection2                from '../components/AboutSection2'
// import CommonQuestionsSection       from '../components/CommonQuestionsSection'

import Styles from './LandingPage.module.css'


const LandingPage = () => {

    const title             = 'Personalized Astrology Readings & Horoscopes | StarGoddess Astrology'
    const metaDescription   = `Let StarGoddess illuminate your path with free daily horoscopes, in-depth year-ahead readings, love compatibility and transformative astrological insights`
    const websiteUrl        = `${window.location.href}`

    return (
    <>
    <Helmet>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={websiteUrl} />
        <meta property="og:url" content={websiteUrl} />
    </Helmet>

    <div className={Styles.page}>
        <HeroSection2 />
        <ForecastSection />
        <LoveCompatibilitySection />
        <YearAheadSection />
        <PersonalReadingSection />
        <AboutSection2 />
    </div>
    </>
    )
};

export default LandingPage

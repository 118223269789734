import React, { useState, useEffect }       from 'react'
import Spinner                              from './Spinner'
import axiosInstance                        from '../utils/axiosInstance'
import { getNowUTCDate }                    from '../utils/utils'

import styles from './Forecast.module.css'


// duration = { 'day', 'week'}
// period = {'previous', 'current', 'next'}

const Forecast = ({ duration = 'day', period = 'current' }) => {

    const [loading, setLoading]     = useState(true);
    const [starSign, setStarSign]   = useState('');
    const [forecast, setForecast]   = useState('');
    const [title, setTitle]         = useState('');
    //const [dateFrom, setDateFrom]   = useState();


    const getStartOfWeek = (date) => {
        const startOfWeek = new Date(date);
        startOfWeek.setUTCDate(date.getUTCDate() - date.getUTCDay());
        return startOfWeek;
    };

    const getDayForecastTitle = (date) => {
        const options       = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);

        // Correctly handle the formatted date
        const [weekday, month, day, year] = formattedDate.replace(',', '').split(' ');

        const ordinal = (n) => {
            const s = ["th", "st", "nd", "rd"];
            const v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        return `Daily Forecast, ${weekday} ${ordinal(parseInt(day, 10))} ${month} ${year}`;
    };

    const getStartOfWeekTitle = (startOfWeek) => {

        // Format options for the date
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

        // Format the start of the week date using UTC
        const formattedDate = startOfWeek.toLocaleDateString('en-US', {
            ...options,
            timeZone: 'UTC'
        });

        // Split the formatted date to insert the ordinal indicator
        const [weekday, month, day, year] =
            formattedDate.replace(',', '').split(' ');

        const ordinal = (n) => {
            const s = ["th", "st", "nd", "rd"];
            const v = n % 100;
            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        };

        return `Weekly Forecast for ${weekday} ${ordinal(parseInt(day, 10))} ${month} ${year}`;
    };

    const getForecastDate = () => {

        const today = getNowUTCDate();

        if (period === 'current')
            return (duration === 'week') ? getStartOfWeek(today) : today;

        if (duration === 'day')
            today.setUTCDate(today.getUTCDate() + ((period === 'previous') ? -1 : 1));

        else if (duration === 'week')
        {
            const offset = (period === 'previous') ? -7 : 7;
            today.setUTCDate(today.getUTCDate() + offset);
            return getStartOfWeek(today);
        }

        return today;
    };


    useEffect(() => {

        console.log(`duration: ${duration}, period: ${period}\n`);

        const fetchForecast = async () => {
            try
            {
                setLoading(true);

                const dt = getForecastDate();

                const url =
                    `${process.env.REACT_APP_API_ENDPOINT}/api/forecast/userForecast`

                const response = await axiosInstance.get(url, {
                    params: {
                        duration: duration,
                        dateFrom: dt.toISOString().split('T')[0]
                    }
                });

                const { starsign, forecast } = response.data;

                setForecast(forecast.forecast);
                setStarSign(starsign);
            }
            catch (error)
            {
                console.error('Error fetching forecast:', error);
                setForecast('Error fetching forecast, please try again later.');
            }
            finally
            {
                setLoading(false);
            }
        };

        const dt = getForecastDate();

        setTitle((duration === 'day') ? getDayForecastTitle(dt) : getStartOfWeekTitle(dt));

        fetchForecast();

    // eslint-disable-next-line
    }, [duration, period]);

    return (
        <div className={styles.textContainer}>
            <div className={styles.imageContainer}>
                <img src={`/img/starsigns/25/${starSign.toLowerCase()}.png`} alt={`${starSign}`} className={styles.starSignImage} />
            </div>
            <h2 className={styles.title}>{title}</h2>
            {loading ? <Spinner sizeClass="small" /> : <div dangerouslySetInnerHTML={{ __html: forecast }} />}
        </div>
    );
};

export default Forecast
